<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-add-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <NewTPRRAAssessment
        :reloadParent="load"
        :closeSidebar="closeAddAssessmentSidebar"
      />
    </b-sidebar>
    <b-sidebar
      lazy
      id="sidebar-edit-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <EditTPRRAAssessment
        v-if="selectedAssessmentId !== null"
        :reloadParent="load"
        :closeSidebar="closeEditAssessmentSidebar"
        :assessmentId="selectedAssessmentId"
      />
    </b-sidebar>
    <div class="card" v-if="assessments !== null">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Assessments</h3>
            <p class="mb-0">Manage your Third Party assessments.</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <b-button
              size="sm"
              v-if="!isUserVendor"
              @click="
                () => {
                  $router.push('/thirdpartyrisksRA/assessments/new');
                }
              "
              variant="primary"
              ><feather-icon class="mr-50" icon="PlusIcon" />New Assessment</b-button
            >
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="assessmentFilters.search == null || assessmentFilters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    assessmentFilters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="assessmentFilters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
        <div class="col-sm-4">
          <div class="w-100 d-flex align-items-center justify-content-end"></div>
        </div>
      </div>
      <div class="table-responsive">
        <table role="table" class="table table-hover table-condensed">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" class="text-center" scope="col">Third Parties</th>
              <!-- <th role="columnheader" scope="col">Description</th> -->
              <th role="columnheader" scope="col" class="text-center">Status</th>
              <th role="columnheader" scope="col" class="text-center">Questionnaires</th>
              <!-- <th role="columnheader" scope="col" class="text-center">Risk Value</th> -->
              <th role="columnheader" scope="col" class="text-center">Progress</th>
              <!-- <th role="columnheader" scope="col" class="text-center">Compliance</th> -->
              <th role="columnheader" scope="col" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="(assessment, index) in assessments"
              :key="assessment._id"
              role="row"
              class="cursor-pointer"
              @click="handleAssessmentClick(assessment._id)"
            >
              <th>
                {{
                  (assessmentFilters.page - 1) * assessmentPagination.perPage + index + 1
                }}
              </th>
              <td role="cell" style="max-width: 20vw">
                <div
                  class="w-100 d-flex flex-column align-items-start justify-content-center"
                >
                  <span class="font-weight-bold mb-25">{{ assessment.title }}</span>
                  <small>{{ assessment.description }}</small>

                  <!-- <div class="d-flex align-items-center justify-content-start">
                    <b-badge
                      v-if="assessment.status === 3"
                      variant="light-warning"
                      class="mt-25"
                      >Draft</b-badge
                    >
                  </div> -->
                  <!-- <small class="text-secondary"
                    >Last Update:
                    <span class="font-weight-bold">{{
                      assessment.updated_at | moment
                    }}</span></small
                  > -->
                  <!-- <b-badge
                    v-b-tooltip.top.hover.v-dark
                    title="Last Update"
                    variant="light-info"
                    pill
                    >{{ assessment.updated_at | moment }}</b-badge
                  > -->
                </div>
              </td>

              <!-- <td role="cell" style="max-width: 20vw">
                {{ assessment.description }}
              </td> -->

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-badge
                    v-if="assessment.vendor_ids && assessment.vendor_ids != null"
                    variant="dark"
                    >{{ assessment.vendor_ids.length }}</b-badge
                  >
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-badge :variant="getStatusColor(parseInt(assessment.status))">{{
                    getStatusNumber(parseInt(assessment.status))
                  }}</b-badge>
                </div>
              </td>
              
              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="assessment.total_questionnaires.toString()"
                    variant="light-primary"
                  />
                </div>
              </td>
              <!-- <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="assessment.risk_value.toString()"
                    variant="light-success"
                    v-if="assessment.risk_value"
                  />
                  <b-avatar text="0" variant="light-success" v-else />
                </div>
              </td> -->

              <td role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <!-- <donut-bar
                      :percentage="Number(assessment.completion.toFixed(2))"
                      :displayValue="assessment.completion + '%'"
                      size="xsm"
                      :uniqueId="assessment._id + 2"
                      :colorsReversed="true"
                    /> -->
                  <progress-badge
                    :min="0"
                    :max="100"
                    title="Progress"
                    :value="assessment.completion"
                    :colorsReversed="true"
                  />
                </div>
              </td>

              <!-- <td role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <progress-badge
                    :min="0"
                    :max="100"
                    title="Compliance"
                    :value="getCompliance(assessment.gap)"
                  />
                </div>
              </td> -->
              <td role="cell">
                <div class="d-flex align-items-center justify-content-center w-100">
                  <b-dropdown size="sm" text="Select" variant="outline-info">
                    <b-dropdown-item @click.stop="handleEditClick(assessment._id)"
                      ><span class="align-middle">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span class="align-middle">Edit</span>
                      </span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click.stop="
                        () => {
                          $router.push(
                            `/thirdpartyrisksRA/assessments/edit/${assessment._id}?startIndex=2`
                          );
                        }
                      "
                      ><span class="align-middle">
                        <feather-icon icon="SendIcon" class="mr-50" />
                        <span class="align-middle">Invite Third Parties</span>
                      </span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="assessment.status === 3"
                      @click.stop="handlePublishClick(assessment._id)"
                      ><span class="align-middle">
                        <feather-icon icon="ArrowUpCircleIcon" class="mr-50" />
                        <span class="align-middle">Publish</span>
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <b-button
                    @click.stop="handleEditClick(assessment._id)"
                    variant="info"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button> -->
                  <!-- <b-button
                    @click.stop="handleEditClick(assessment._id)"
                    variant="primary"
                    size="sm"
                    ><span class="align-middle"
                      ><feather-icon icon="EditIcon" class="mr-50" /><span
                        class="align-middle"
                        >Edit</span
                      ></span
                    ></b-button
                  > -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-pagination
            class="mt-2"
            v-model="assessmentFilters.page"
            :per-page="assessmentPagination.perPage"
            :total-rows="assessmentPagination.totalPages * assessmentPagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ProgressBadge from "@/components/ProgressBadge.vue";

import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import NewTPRRAAssessment from "./components/NewTPRRAAssessment.vue";
import EditTPRRAAssessment from "./components/EditTPRRAAssessment.vue";
import moment from "moment";
export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    NewTPRRAAssessment,
    EditTPRRAAssessment,
    ProgressBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    moment,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      selectedAssessmentId: null,
      assessments: null,
      isUserVendor: false,
      vendorUsers: null,
      vendorUsersFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
      assessmentFilters: {
        page: 1,
        search: null,
      },
      assessmentPagination: {
        perPage: 10,
        totalPages: 1,
      },
    };
  },

  mixins: [
    ResponseMixins,
    TaskMixins,
    UtilsMixins,
    QuestionnaireMixins,
    ThirdPartyRisksRAMixins,
  ],

  watch: {

    vendorUsersFilters: {
      handler: function (newValue) {
        this.getVendorUsersAndSetData(newValue);
      },
      deep: true,
    },
    assessmentFilters: {
      handler: function (newValue) {
        this.getAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }

      this.getAssessmentsAndSetData(this.assessmentFilters, this.$route.params.id);
    },

    handlePublishClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to publish this Assessment?", {
          title: "Warning",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value == true) {
            this.handlePublishAssessment(id);
          }
        });
    },

    handlePublishAssessment(id) {
      this.showOverlay = true;
      this.updateTPRAssessment({ status: 1 }, id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getCompliance(gap) {
      return 100 - gap;
    },

    handleEditClick(assessmentId) {
      // this.selectedAssessmentId = assessmentId;
      // this.openEditAssessmentSidebar();
      this.$router.push(`/thirdpartyrisksRA/assessments/edit/${assessmentId}`);
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        case 3:
          return "Draft";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        case 3:
          return "warning";
        default:
          return "dark";
      }
    },

    handleAssessmentClick(id) {
      this.$router.push(`/thirdpartyrisksRA/assessments/${id}/details`);
    },

    getAssessmentsAndSetData(filters) {
      this.showOverlay = true;
      this.getAssessments(filters)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.assessmentFilters.page = res.data.data.current_page;
          this.assessmentPagination.perPage = res.data.data.per_page;
          this.assessmentPagination.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewAssessmentClick() {
      this.openAddAssessmentSidebar();
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAddAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
    },
    openAddAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
      });
    },
    closeEditAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
    },
    openEditAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
